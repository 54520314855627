// TODO: type this file properly

//   g/kWh
export const electricGridMixGHGEmissions = {
  '2021': {
    US: 442.43,
    ASCC: 606.11,
    FRCC: 486.17,
    HICC: 937.19,
    MRO: 628.1,
    NPCC: 253.76,
    RFC: 534.09,
    SERC: 414.97,
    SPP: 509.85,
    TRE: 419.13,
    WECC: 360.31,
    CA: 281.76,
  },
  '2022': {
    US: 469.51,
    ASCC: 607.94,
    FRCC: 497.09,
    HICC: 890.97,
    MRO: 623.37,
    NPCC: 279.36,
    RFC: 474.94,
    SERC: 500.6,
    SPP: 478.53,
    TRE: 447.67,
    WECC: 373.61,
    CA: 302.13,
  },
  '2023': {
    US: 442.76,
    ASCC: 613.28,
    FRCC: 485.86,
    HICC: 881.50,
    MRO: 598.61, // now MISO
    NPCC: 288.25,
    RFC: 443.90, // now PJM
    SERC: 472.99,
    SPP: 430.87,
    TRE: 402.67,
    WECC: 355.17,
    CA: 281.36,
  },
};

//   g/gallon consumed Fuel Refining
export const fuelGHGEmissionsRefining = {
  '2021': {
    gasoline: 1585.26,
    E10: 1857.83,
    CaE10: 2004.62,
    E15: 1991.73,
    E20: 2126.82,
    E50: 1946.99,
    E85: 2213.56,
    diesel: 1057.1,
    BD5: 1112.39,
    BD11: 1166.91,
    BD20: 1278.52,
    BD50: 1611.93,
    BD100: 2171.34,
    RD100: 1184.18,
    RNG: 62448,
    RNGStationary: 64170,
  },
  '2022': {
    gasoline: 1601.03,
    E10: 1881.3,
    CaE10: 2028.58,
    E15: 2019.01,
    E20: 2157.93,
    E50: 1974.84,
    E85: 2250.06,
    diesel: 1065.7,
    BD5: 1122.85,
    BD11: 1191.49,
    BD20: 1294.57,
    BD50: 1639.29,
    BD100: 2217.99,
    RD100: 1188.89,
    RNG: 62448,
    RNGStationary: 64170,
  },
  '2023': {
    gasoline: 1582.45,
    E10: 1857.79,
    CaE10: 2009.38,
    E15: 1993.29,
    E20: 2129.99,
    E50: 1947.60,
    E85: 2216.88,
    diesel: 1056.28,
    BD5: 1111.89,
    BD11: 1178.69,
    BD20: 1279.00,
    BD50: 1614.49,
    BD100: 2177.68,
    RD100: 1173.28,
    RNG: 62448,
    RNGStationary: 64170,
  },
};

//   g/gallon consumed tailpipe
export const fuelGHGEmissions = {
  '2021': {
    gasoline: 8957.79,
    E10: 8644.07,
    CaE10: 8644.07,
    E15: 8477.38,
    E20: 8316.82,
    E50: 7353.59,
    E85: 6281.4,
    E100: 6248.67,
    diesel: 10163.26,
    BD5: 10129.58,
    BD11: 10088.87,
    BD20: 10028.58,
    BD50: 9826.59,
    BD100: 9490.05,
    RD100: 9471.32,
    RNG: 62448,
    RNGStationary: 64170,
  },
  '2022': {
    gasoline: 8958.53,
    E10: 8644.81,
    CaE10: 8644.81,
    E15: 8478.11,
    E20: 8317.53,
    E50: 7354.22,
    E85: 6281.94,
    E100: 6248.67,
    diesel: 10249.95,
    BD5: 10216.27,
    BD11: 10179.07,
    BD20: 10118.44,
    BD50: 9913.24,
    BD100: 9576.66,
    RD100: 9554.71,
    RNG: 62448,
    RNGStationary: 64170,
  },
  '2023': {
    gasoline: 8958.53,
    E10: 8643.53,
    CaE10: 8354.66,
    E15: 8476.84,
    E20: 8316.29,
    E50: 7353.12,
    E85: 6280.99,
    E100: 6248.67,
    diesel: 10249.95,
    BD5: 10216.27,
    BD11: 10175.86,
    BD20: 10115.25,
    BD50: 9913.24,
    BD100: 9576.66,
    RD100: 9554.71,
    RNG: 62448,
    RNGStationary: 64170,
  },
};

//   g/gallon consumed lifecycle
export const fuelGHGEmissionsLifecycle = {
  '2021': {
    gasoline: 11354.37,
    E10: 10673.25,
    CaE10: 10601.37,
    E15: 10320.63,
    E20: 9975.69,
    E50: 7909.02,
    E85: 5551.61,
    E100: 4900.69,
    diesel: 12260.96,
    BD5: 11838.86,
    BD11: 11408.53,
    BD20: 10570.96,
    BD50: 8027.67,
    BD100: 3764.96,
    RD100: 6333.07,
    RNG: 62448,
    RNGStationary: 64170,
  },
  '2022': {
    gasoline: 11395.19,
    E10: 10719.38,
    CaE10: 10629.05,
    E15: 10369.35,
    E20: 10027.03,
    E50: 7976.0,
    E85: 5637.35,
    E100: 4900.69,
    diesel: 12387.43,
    BD5: 11968.46,
    BD11: 11468.43,
    BD20: 10712.84,
    BD50: 8184.56,
    BD100: 3950.09,
    RD100: 6645.55,
    RNG: 62448,
    RNGStationary: 64170,
  },
  '2023': {
    gasoline: 11333.06,
    E10: 10655.30,
    CaE10: 10334.65,
    E15: 10305.26,
    E20: 9962.89,
    E50: 7911.57,
    E85: 5572.10,
    E100: 4900.69,
    diesel: 12322.14,
    BD5: 11905.31,
    BD11: 11404.74,
    BD20: 10653.10,
    BD50: 8140.70,
    BD100: 3927.81,
    RD100: 8932.75,
    RNG: 62448,
    RNGStationary: 64170,
  },
};

// percent biofuel (ethanol/biodiesel) as decimal
export const PERCENT_BLENDS = {
  // gas & ethanol
  gasoline: 0,
  E10: 0.1,
  CaE10: 0.1,
  E15: 0.15,
  E20: 0.2,
  E50: 0.5,
  E85: 0.85,
  E100: 1,
  // diesel & biodiesel
  diesel: 0,
  BD5: 0.05,
  BD11: 0.11,
  BD20: 0.2,
  BD50: 0.5,
  BD100: 1,
  RD100: 1,
  // natural gas
  RNG: 0,
  RNGStationary: 0,
} as const;

export type FuelType = keyof typeof PERCENT_BLENDS;

//   g/mmBtu
export const natrualGasGHGEmissions = {
  stationaryFuels: 64170,
  electricityGeneration: 62448,
};

export const mapDbKeyToFuelType: Record<string, FuelType> = {
  annualRD100Consumed: 'RD100',
  annualRD100ProducedAndPurchased: 'RD100',
  annualBlendedRD100Sold: 'RD100',
  customerAnnualRD100Consumed: 'RD100',
  customerAnnualRD100Sold: 'RD100',
  annualBD100Consumed: 'BD100',
  annualBD100ProducedAndPurchased: 'BD100',
  annualBlendedBD100Sold: 'BD100',
  customerAnnualBD100Consumed: 'BD100',
  customerAnnualBD100Sold: 'BD100',
  annualBD5Consumed: 'BD5',
  annualBD5ProducedAndPurchased: 'BD5',
  annualBlendedBD5Sold: 'BD5',
  customerAnnualBD5Consumed: 'BD5',
  customerAnnualBD5Sold: 'BD5',
  annualBD11Consumed: 'BD11',
  annualBD11ProducedAndPurchased: 'BD11',
  annualBlendedBD11Sold: 'BD11',
  customerAnnualBD11Consumed: 'BD11',
  customerAnnualBD11Sold: 'BD11',
  annualBD20Consumed: 'BD20',
  annualBD20ProducedAndPurchased: 'BD20',
  annualBlendedBD20Sold: 'BD20',
  customerAnnualBD20Consumed: 'BD20',
  customerAnnualBD20Sold: 'BD20',
  annualBD50Consumed: 'BD50',
  annualBD50ProducedAndPurchased: 'BD50',
  annualBlendedBD50Sold: 'BD50',
  customerAnnualBD50Consumed: 'BD50',
  customerAnnualBD50Sold: 'BD50',
  annualE100Consumed: 'E100',
  annualE10Consumed: 'E10',
  annualE10ProducedAndPurchased: 'E10',
  annualBlendedE10Sold: 'E10',
  customerAnnualE10Consumed: 'E10',
  customerAnnualE10Sold: 'E10',
  annualCAE10Consumed: 'CaE10',
  annualCAE10ProducedAndPurchased: 'CaE10',
  annualBlendedCAE10Sold: 'CaE10',
  annualE15Consumed: 'E15',
  annualE15ProducedAndPurchased: 'E15',
  annualBlendedE15Sold: 'E15',
  customerAnnualE15Consumed: 'E15',
  customerAnnualE15Sold: 'E15',
  annualE20Consumed: 'E20',
  annualE20ProducedAndPurchased: 'E20',
  annualBlendedE20Sold: 'E20',
  customerAnnualE20Consumed: 'E20',
  customerAnnualE20Sold: 'E20',
  annualE50Consumed: 'E50',
  annualE50ProducedAndPurchased: 'E50',
  annualBlendedE50Sold: 'E50',
  customerAnnualE50Consumed: 'E50',
  customerAnnualE50Sold: 'E50',
  annualE85Consumed: 'E85',
  annualE85ProducedAndPurchased: 'E85',
  annualBlendedE85Sold: 'E85',
  customerAnnualE85Consumed: 'E85',
  customerAnnualE85Sold: 'E85',
  annualGasolineConsumed: 'gasoline',
  gasolineProducedAndPurchased: 'gasoline',
  unblendedGasolineSold: 'gasoline',
  customerAnnualGasConsumed: 'gasoline',
  customerAnnualGasSold: 'gasoline',
  annualDieselConsumed: 'diesel',
  dieselProducedAndPurchased: 'diesel',
  unblendedDieselSold: 'diesel',
  customerAnnualDieselConsumed: 'diesel',
  customerAnnualDieselSold: 'diesel',
  annualNaturalGasConsumed: 'RNG',
  customerAnnualNaturalGasConsumed: 'RNG',
  customerAnnualNaturalGasSold: 'RNG',
  annualNaturalGasConsumedStationary: 'RNGStationary',
  customerAnnualNaturalGasConsumedStationary: 'RNGStationary',
  annualNaturalGasProducedAndPurchased: 'RNG',
};
